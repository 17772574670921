@import "./variables";

/// cut text in the end of second line and make dots at the end
@mixin text-two-lines {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  overflow-wrap: break-word;
  // info: ensure to set a (max)-width to the element as well as
}

///
@mixin dash-app-container {
  height: 100%;
  padding: 30px 20px 25px;
  background-color: $white;
  border-radius: 15px;
  box-shadow: $shadow-grey;
  transition: box-shadow 0.3s ease;
}

///
@mixin dash-app-shadows {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.15), 0 10px 10px rgba(0, 0, 0, 0.12);
}

///
@mixin dash-app-name {
  height: 34px;
  padding: 0 5px;
  color: $grey-dark;
  font-size: 14px;
  line-height: 1.2;
  text-align: center;
  @include text-two-lines;
}

///
@mixin dash-app-image-wrapper {
  margin-bottom: 15px;
  padding: 0 20px;
}

///
@mixin interaction-icon {
  padding: $space-s;
  font-size: 1.25rem;
}

///
@mixin add-file-icon {
  position: absolute;
  right: 15px;
  bottom: 0;
  transform: rotate(135deg);
  font-size: 1.575rem;
  color: $primary-color;
}

/// for input-fields in the community
@mixin text-entry-item {
  margin-bottom: 0;

  .item-input,
  .item-title {
    resize: none;
    padding: 5px 10px;
    border-top: 0 none;
    border-right: 0 none;
    border-left: 0 none;
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.375rem;

    &::placeholder,
    &::-ms-input-placeholder {
      color: $grey-dark-half;
    }

    &:hover {
      border-color: $grey-sidebar-item-bg;
    }

    &:focus {
      border-color: transparent;
      box-shadow: 0 0 1px $grey-dark-color;
    }
  }
}

$b-radius: 4px;

///
@mixin Editor-container-styles {
  .jodit_toolbar,
  .jodit_statusbar {
    background-color: #fafafa;
  }

  // hide bottom statusbar
  .jodit_statusbar {
    display: none;
  }

  .jodit_toolbar.jodit_default_theme {
    border-top-right-radius: $b-radius;
    border-top-left-radius: $b-radius;
    border-color: $grey-sidebar-item-bg;

    > .jodit_toolbar_btn {
      height: 36px;
      line-height: 36px;

      &.jodit_toolbar_btn-separator {
        height: 34px;
      }
      &:not(.jodit_toolbar-input):hover {
        background-color: $grey-light-background;
      }
    }

    .jodit_form {
      .jodit_button {
        height: unset;
        line-height: unset;
        margin-top: 7px;
        margin-bottom: 0;
        padding: 9px 7px 7px;
        background-color: $grey-light-background;

        &:hover {
          background-color: $primary-color;
          color: $white;
        }
      }

      label input[type="checkbox"] {
        top: -2px;
      }
    }

    .jodit_tabs_buttons {
      a:first-child {
        border-top-left-radius: $b-radius;
        border-bottom-left-radius: $b-radius;
      }
      a:last-child {
        border-top-right-radius: $b-radius;
        border-bottom-right-radius: $b-radius;
      }
    }
  }

  // text selection drop down
  .jodit_toolbar_list > .jodit_toolbar li.jodit_toolbar_btn > a {
    padding: 2px 15px;

    &:active,
    &:focus,
    &:hover {
      background-color: $grey-light-background;
    }
  }

  .jodit_workplace {
    max-height: 300px;
    border-bottom-right-radius: $b-radius;
    border-bottom-left-radius: $b-radius;
    border-color: $grey-sidebar-item-bg;
  }

  .jodit_toolbar,
  .jodit_workplace {
    .jodit_toolbar_popup,
    .jodit_input,
    .jodit_textarea {
      border-radius: $b-radius;
    }
  }
}

///
@mixin Editor-content-styles {
  ul,
  ol,
  ul li,
  ol li {
    list-style-position: inside;
  }

  blockquote {
    padding: 5px;

    & > p {
      margin-bottom: 0;
    }
  }
}

///
@mixin text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

///
@mixin scope-nav-menu-transparent {
  background-color: transparent;
  border: none;
}

///
@mixin scope-nav-menu-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  &.ant-menu-item-selected {
    background-color: $grey-medium;
    border-radius: 6px;
    color: $grey-dark;
    // TODO pc: update hover state
    &:after {
      opacity: 0;
    }

    a,
    a span {
      color: $blue-link;
    }
  }

  a {
    display: flex;
    align-items: center;
    width: 100%;

    .menu-item-icon {
      margin-right: 0;
      font-size: 1rem;
    }

    span {
      @include text-ellipsis;
      margin-left: $space-s;
      font-size: 0.875rem;
      color: $grey-dark;
    }
  }

  &.ant-menu-item-active {
    a,
    a span {
      color: $blue-link;
    }
  }
}

///
@mixin grey-input {
  background-color: $grey-light; // $grey-medium;

  &:hover,
  &:focus {
    background-color: $white;
  }
}

///
@mixin btn-toggle-sidebar {
  position: absolute;
  background-color: $grey-dark-bg;
  border-color: $grey-dark-bg;
  border-bottom-color: $black-font;
  border-radius: 1px;

  i {
    font-size: 1rem;
  }
}

///
@mixin btn-toggle-sidebar-left {
  @include btn-toggle-sidebar;
  left: 0;
}

///
@mixin btn-toggle-sidebar-right {
  @include btn-toggle-sidebar;
  right: 0;
}

///
@mixin message-bubble($color, $backgroundColor) {
  max-width: 400px;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 5px 10px;
  background-color: $backgroundColor;
  color: $color;

  .link-item {
    color: $color;
    text-decoration: underline;
  }
}

///
@mixin incoming-message {
  margin-left: 0;
  padding-bottom: $space-s;
  p,
  .incoming-message-bubble,
  .end-message-bubble {
    border-radius: 0px 10px 10px;
    @include message-bubble($black, $grey-light);
  }
}

///
@mixin outgoing-message {
  margin-right: 0;
  padding-bottom: $space-s;
  p,
  .outgoing-message-bubble {
    border-radius: 10px 0px 10px 10px;
    @include message-bubble($white, $primary-color);
  }
}

/// e.g. pc-scope table/chat
@mixin avatar-item {
  margin-right: 5px;
  background-color: $grey-light-background;
  text-transform: capitalize;
}

///
@mixin logos-shared-style {
  .item-logo-rectangle,
  .img-play-store,
  .img-bmwi,
  .img-ce {
    width: auto;
    margin-top: 1rem;
    padding: 3px;
  }
}

///
@mixin default-footer-logos {
  @include logos-shared-style;
  .item-logo-rectangle,
  .img-play-store {
    height: 70px;
  }

  .img-bmwi,
  .img-ce {
    height: 100px;
  }

  .img-dace {
    width: 85px;
    height: 85px;
  }

  .item-logo-rectangle,
  .img-bmwi,
  .img-dace {
    margin-left: $space-l;
  }
}

///
@mixin small-footer-logos {
  @include logos-shared-style;

  .item-logo-rectangle,
  .img-play-store {
    height: 45px;
  }

  .img-bmwi,
  .img-ce {
    height: 70px;
  }

  .img-dace {
    margin-top: 1rem;
    width: 42.5px;
    height: 42.5px;
  }

  .item-logo-rectangle,
  .img-play-store,
  .img-bmwi,
  .img-dace {
    margin-left: $space-m;
  }
}

///
@mixin scope-user-delete-btn {
  max-width: 150px;
  color: $grey-dark-border;
  background-color: $grey-medium;
  border: none;
}

///
@mixin white-content-wrapper {
  overflow: hidden;
  margin: 0px;
  padding: 10px 10px !important;
  box-shadow: $shadow-grey;
  background-color: $white;
  border-radius: 8px;
  box-sizing: border-box;
}

///
@mixin page-content-wrapper {
  //height: calc(100vh - 80px - 2rem);
  @include white-content-wrapper;

  &.mobile-content {
    height: calc(100vh - 97px - 2rem);
  }
}

///
@mixin tag-state {
  padding: 3px 7px;
  font-size: 0.875rem;
}

///
@mixin rounded-tage-state {
  display: block;
  width: 20px;
  height: 20px;
  background-color: transparent;
  border-radius: 50%;

  &.default-state {
    border: 1px solid $grey-light-background;
  }
}
