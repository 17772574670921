@import './../abstract/mixins/flex.scss';
@import './../abstract/mixins/breakpoints.scss';

///
$secundaryColor: #f29663;

///
.dtb-header {
  .ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover {
    background-color: transparent !important;
  }
}

///
.dtb-layout {
  .dtb-header,
  .dtb-header-menu {
    padding: 0px 0px !important; // mobil header

    .ant-avatar {
      background-color: $secundaryColor;
    }

    .anticon,
    .ant-menu-item-icon {
      color: $secundaryColor;
    }
  }

  .dtb-header {
    height: 60px;
    width: 100%;
    z-index: 800;
    top: 0;
    @include grid-child(1, 3);
    padding: 0;
    //line-height: 60px;

    // offcanvas icons
    .trigger,
    .trigger-two {
      color: $secundaryColor;
      height: 60px;
      width: 60px;
      float: left;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    // welcome text
    .dtb-header-subtitle,
    .language-menu,
    .dtb-grettings {
      display: none;
    }

    .dtb-logo-link {
      height: 60px;
      line-height: 60px;
      width: 100%;
      .dtb-logo {
        margin-left: 0px;
      }

      img {
        margin-top: 14px;
      }

      // subtitle logo
      .dtb-header-subtitle {
        height: 60px;
        width: 258px;
        overflow: hidden;
      }
    }

    .dtb-head-wrapper {
      .dtb-logo-link {
        color: inherit;
        margin-top: 0px;
        float: left;

        .dtb-logo {
          margin-left: 0px;
        }
      }

      .dtb-header-subtitle,
      .dtb-header-subtitle:hover {
        font-weight: bold;
        margin-left: 25px;
        text-transform: uppercase;
        color: $secundaryColor;
      }

      .dtb-planner-link,
      .dtb-planner-link:hover {
        display: none;
        float: left;
        box-sizing: border-box;
        margin-left: 10px;
        color: $secundaryColor !important;
      }

      .dtb-language-switch {
        // float: right;
        text-align: left;
        display: flex;
      }

      .dtb-grettings {
        color: $secundaryColor;
        width: auto;
      }
    }

    @include breakpoint('md', 'min') {
      .dtb-head-wrapper {
        .dtb-planner-link,
        .dtb-planner-link:hover {
          display: inline;
        }
      }
    }
    // welcome text visible
    @include breakpoint('xl', 'min') {
      .trigger,
      .trigger-two {
        display: none;
      }

      .dtb-head-wrapper {
        .dtb-logo-link {
          color: inherit;
          margin-top: 0px;
          float: left;

          .dtb-logo {
            margin-left: 20px;
          }
        }
      }

      .dtb-logo-link {
        height: 60px;
        line-height: 60px;

        .dtb-logo {
          margin-left: 20px;
        }

        img {
          margin-top: 14px;
        }
      }

      .dtb-grettings {
        display: inline;
      }

      .dtb-header-subtitle {
        display: inline-flex;
        display: block;
      }
    }

    .ant-menu,
    .none-mobil {
      @include flex(row);
      align-items: baseline;
      width: auto;
    }

    // menu styles
    .dtb-header-menu {
      padding: 0px 0px !important; // mobil header

      .ant-avatar {
        background-color: $secundaryColor;
      }

      .anticon {
        color: $secundaryColor;
      }
    }

    .ant-menu-horizontal:not(.ant-menu-light) > .ant-menu-item,
    .ant-menu-horizontal:not(.ant-menu-light) > .ant-menu-submenu {
      margin-top: -1px;
      margin-bottom: 0;
      padding: 0 10px !important;
    }

    a:first-child {
      display: none;
    }

    .head-wrapper {
      display: flex;
      width: 100%;
      justify-content: space-between;

      .ant-menu-horizontal {
        justify-content: flex-end !important;
      }
    }

    .ant-menu,
    .ant-menu li {
      // height: 60px;
      padding: 0;
      overflow: hidden;
      background: none;
      display: inline-block;
      border-bottom: none;
      margin-top: -1px;
    }

    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
      margin-top: -1px;
      margin-bottom: 0;
      padding: 0 20px;
    }
  }
}
