@import './variables';

///
@font-face {
  font-family: 'comjooIcons';
  src: url('./../assets/font/comjooIcons.eot?sz5izf');
  src: url('./../assets/font/comjooIcons.eot?sz5izf#iefix')
      format('embedded-opentype'),
    url('./../assets/font/comjooIcons.ttf?sz5izf') format('truetype'),
    url('./../assets/font/comjooIcons.woff?sz5izf') format('woff'),
    url('./../assets/font/comjooIcons.svg?sz5izf#comjooIcons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='comjoo-icon'],
[class*=' comjoo-icon'] {
  /* use !important to prevent issues with browser extensions that change ./assets/font/comjoo/comjoo */
  font-family: 'comjooIcons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

///
.comjoo-icon-paper-plane:before {
  content: '\e900';
}

///
.comjoo-icon-send:before {
  content: '\e901';
}

///
.comjoo-icon-call_white:before {
  content: '\e902';
}

///
.comjoo-icon-community:before {
  content: '\e903';
}

///
.comjoo-icon-feedback:before {
  content: '\e904';
}

///
.comjoo-icon-scopes:before {
  content: '\e905';
}

///
.comjoo-icon-scopes_default:before {
  content: '\e906';
}

///
.comjoo-icon-pil:before {
  content: '\e907';
}

///
.comjoo-icon-moonrise:before {
  content: '\e908';
}

///
.comjoo-icon-moon:before {
  content: '\e909';
}

///
.comjoo-icon-night:before {
  content: '\e909';
}

///
.comjoo-icon-weather:before {
  content: '\e909';
}

///
.comjoo-icon-sun1:before {
  content: '\e90a';
}

///
.comjoo-icon-sunrise:before {
  content: '\e90b';
}

///
.comjoo-icon-dawn:before {
  content: '\e90b';
}

///
.comjoo-icon-weather1:before {
  content: '\e90b';
}

///
.comjoo-icon-sunrise1:before {
  content: '\e90d';
}

///
.comjoo-icon-moon-o:before {
  content: '\e90c';
}

///
.comjoo-icon-sun:before {
  content: '\e90e';
}

///
.comjoo-icon-call_white:before,
.comjoo-icon-community:before,
.comjoo-icon-feedback:before,
.comjoo-icon-scopes:before,
.comjoo-icon-scopes_default:before {
  color: $white;
}
